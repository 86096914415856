import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SAFE from "../../images/imtoken/Safe-bg.png"
import IMTOKEN from "../../images/imtoken/imToken-bg.png"
import TOKENLON from "../../images/imtoken/Tokenlon-bg.png"
import IMKEY from "../../images/imtoken/imKey-bg.png"
import { Link } from "gatsby"

export default ({}) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        edges {
          node {
            id
            title
            strapi_id
          }
        }
      }
    }
  `)

  const edges = data.allStrapiCategory.edges
  const catSettings = [
    {
      img: SAFE,
      item: edges.find(t => t.node.strapi_id === 4),
    },
    {
      img: IMTOKEN,
      item: edges.find(t => t.node.strapi_id === 5),
    },
    {
      img: TOKENLON,
      item: edges.find(t => t.node.strapi_id === 11),
    },
    {
      img: IMKEY,
      item: edges.find(t => t.node.strapi_id === 10),
    },
  ]

  return (
    <div className="section guide" id="guide">
      <p className="section-title">快速入门</p>
      <div className="guide-wrap">
        {catSettings.map((t, idx) => {
          const node = t.item.node
          return (
            <Link
              key={node.id}
              to={`/category/${node.strapi_id}`}
              className="guide-card"
            >
              <p className="guide-title">{node.title}</p>
              <img src={catSettings[idx].img} alt={node.title} />
            </Link>
          )
        })}
      </div>
    </div>
  )
}
