import React, { useEffect } from "react"
import OpenApp from "open-app"

import Layout from "../components/layout"
import Digest from "../components/imtoken/digest"
import Banner from "../components/banner"
import Guide from "../components/imtoken/guide"
import Feature from "../components/imtoken/feature"
import { updateFavicon } from "../utils"

import "../assets/css/main.css"

import BANNER_IMG from "../images/imtoken/banner.svg"

const IndexPage = ({}) => {
  useEffect(() => {
    updateFavicon()
    new OpenApp({})
    if ((window as any).imToken) {
      ;(window as any).imToken.callAPI("navigator.configure", {
        navigatorColor: "#73D4DB",
      })
    }
  }, [])

  return (
    <Layout title="钱包指南">
      <Banner
        src={BANNER_IMG}
        title="imToken"
        subtitle="钱包指南"
        isTokenlon={false}
      />
      <Digest />
      <Guide />
      <Feature />
      <div style={{ height: 45 }} />
    </Layout>
  )
}
export const Head = () => <title>钱包指南</title>
export default IndexPage
